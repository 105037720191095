import { createRouter, createWebHashHistory } from 'vue-router';
import Start from '@/questions/Start.vue';

const routes = [{
  path: '/',
  name: 'start',
  component: Start,
}, {
  path: '/spouse',
  name: 'spouse',
  component: () => import(/* webpackChunkName: "spouse" */ '@/partner/questions/Spouse.vue'),
}, {
  path: '/partner',
  name: 'partner',
  component: () => import(/* webpackChunkName: "partner" */ '@/partner/questions/Partner.vue'),
}, {
  path: '/contract',
  name: 'contract',
  component: () => import(/* webpackChunkName: "contract" */ '@/partner/questions/Contract.vue'),
}, {
  path: '/share',
  name: 'share',
  component: () => import(/* webpackChunkName: "share" */ '@/partner/questions/Share.vue'),
}, {
  path: '/children',
  name: 'children',
  component: () => import(/* webpackChunkName: "children" */ '@/parentel1/questions/Children.vue'),
}, {
  path: '/savings',
  name: 'savings',
  component: () => import(/* webpackChunkName: "savings" */ '@/questions/Savings.vue'),
}, {
  path: '/result-2',
  name: 'result-2',
  component: () => import(/* webpackChunkName: "result-2" */ '@/parentel2/Result.vue'),
}, {
  path: '/result-1',
  name: 'result-1',
  component: () => import(/* webpackChunkName: "result-1" */ '@/parentel1/Result.vue'),
}, {
  path: '/father',
  name: 'father',
  component: () => import(/* webpackChunkName: "father" */ '@/parentel2/questions/Father.vue'),
}, {
  path: '/halfsiblings-father',
  name: 'halfsiblings-father',
  component: () => import(/* webpackChunkName: "halfsiblings-father" */ '@/parentel2/questions/HalfsiblingsFather.vue'),
}, {
  path: '/mother',
  name: 'mother',
  component: () => import(/* webpackChunkName: "mother" */ '@/parentel2/questions/Mother.vue'),
}, {
  path: '/halfsiblings-mother',
  name: 'halfsiblings-mother',
  component: () => import(/* webpackChunkName: "halfsiblings-mother" */ '@/parentel2/questions/HalfsiblingsMother.vue'),
}, {
  path: '/siblings',
  name: 'siblings',
  component: () => import(/* webpackChunkName: "siblings" */ '@/parentel2/questions/Siblings.vue'),
}, {
  path: '/grandparents',
  name: 'grandparents',
  component: () => import(/* webpackChunkName: "grandparents" */ '@/parentel3/questions/Grandparents.vue'),
}, {
  path: '/grandparents-father-father',
  name: 'grandparentsFatherFather',
  component: () => import(/* webpackChunkName: "grandparentsFatherFather" */ '@/parentel3/questions/GrandparentsFatherFather.vue'),
}, {
  path: '/grandparents-father-father-halfsiblings',
  name: 'grandparentsFatherFatherHalfsiblings',
  component: () => import(/* webpackChunkName: "grandparentsFatherFatherHalfsiblings" */ '@/parentel3/questions/GrandparentsFatherFatherHalfsiblings.vue'),
}, {
  path: '/grandparents-father-mother',
  name: 'grandparentsFatherMother',
  component: () => import(/* webpackChunkName: "grandparentsFatherMother" */ '@/parentel3/questions/GrandparentsFatherMother.vue'),
}, {
  path: '/grandparents-father-mother-halfsiblings',
  name: 'grandparentsFatherMotherHalfsiblings',
  component: () => import(/* webpackChunkName: "grandparentsFatherMotherHalfsiblings" */ '@/parentel3/questions/GrandparentsFatherMotherHalfsiblings.vue'),
}, {
  path: '/grandparents-father-aunts',
  name: 'grandparentsFatherAunts',
  component: () => import(/* webpackChunkName: "grandparentsFatherAunts" */ '@/parentel3/questions/GrandparentsFatherAunts.vue'),
}, {
  path: '/grandparents-mother-father',
  name: 'grandparentsMotherFather',
  component: () => import(/* webpackChunkName: "grandparentsMotherFather" */ '@/parentel3/questions/GrandparentsMotherFather.vue'),
}, {
  path: '/grandparents-mother-father-halfsiblings',
  name: 'grandparentsMotherFatherHalfsiblings',
  component: () => import(/* webpackChunkName: "grandparentsMotherFatherHalfsiblings" */ '@/parentel3/questions/GrandparentsMotherFatherHalfsiblings.vue'),
}, {
  path: '/grandparents-mother-mother',
  name: 'grandparentsMotherMother',
  component: () => import(/* webpackChunkName: "grandparentsMotherMother" */ '@/parentel3/questions/GrandparentsMotherMother.vue'),
}, {
  path: '/grandparents-mother-mother-halfsiblings',
  name: 'grandparentsMotherMotherHalfsiblings',
  component: () => import(/* webpackChunkName: "grandparentsMotherMotherHalfsiblings" */ '@/parentel3/questions/GrandparentsMotherMotherHalfsiblings.vue'),
}, {
  path: '/grandparents-mother-aunts',
  name: 'grandparentsMotherAunts',
  component: () => import(/* webpackChunkName: "grandparentsMotherAunts" */ '@/parentel3/questions/GrandparentsMotherAunts.vue'),
}, {
  path: '/great-grandmother',
  name: 'greatGrandmother',
  component: () => import(/* webpackChunkName: "greatGrandmother" */ '@/parentel4/questions/GreatGrandmother.vue'),
}, {
  path: '/great-grandmother-count',
  name: 'greatGrandmotherCount',
  component: () => import(/* webpackChunkName: "greatGrandmotherCount" */ '@/parentel4/questions/GreatGrandmotherCount.vue'),
}, {
  path: '/great-grandmother-first',
  name: 'greatGrandmotherFirst',
  component: () => import(/* webpackChunkName: "greatGrandmotherFirst" */ '@/parentel4/questions/GreatGrandmotherFirst.vue'),
}, {
  path: '/great-grandmother-second',
  name: 'greatGrandmotherSecond',
  component: () => import(/* webpackChunkName: "greatGrandmotherSecond" */ '@/parentel4/questions/GreatGrandmotherSecond.vue'),
}, {
  path: '/result-3',
  name: 'result-3',
  component: () => import(/* webpackChunkName: "result-3" */ '@/parentel3/Result.vue'),
}, {
  path: '/great-grandfather',
  name: 'greatGrandfather',
  component: () => import(/* webpackChunkName: "greatGrandfather" */ '@/parentel4/questions/GreatGrandfather.vue'),
}, {
  path: '/great-grandfather-count',
  name: 'greatGrandfatherCount',
  component: () => import(/* webpackChunkName: "greatGrandfatherCount" */ '@/parentel4/questions/GreatGrandfatherCount.vue'),
}, {
  path: '/great-grandfather-first',
  name: 'greatGrandfatherFirst',
  component: () => import(/* webpackChunkName: "greatGrandfatherFirst" */ '@/parentel4/questions/GreatGrandfatherFirst.vue'),
}, {
  path: '/great-grandfather-second',
  name: 'greatGrandfatherSecond',
  component: () => import(/* webpackChunkName: "greatGrandfatherSecond" */ '@/parentel4/questions/GreatGrandfatherSecond.vue'),
}, {
  path: '/result-4',
  name: 'result-4',
  component: () => import(/* webpackChunkName: "result-4" */ '@/parentel4/Result.vue'),
}, {
  path: '/result-partner',
  name: 'result-partner',
  component: () => import(/* webpackChunkName: "result-partner" */ '@/parentel4/ResultPartner.vue'),
}, {
  path: '/result-country',
  name: 'result-country',
  component: () => import(/* webpackChunkName: "result-country" */ '@/parentel4/ResultCountry.vue'),
}, {
  path: '/start-calculation',
  name: 'start-calculation',
  component: () => import(/* webpackChunkName: "start-calculation" */ '@/questions/PreForm.vue'),
}];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
