import { setValue } from '@/store/mutations';

// eslint-disable-next-line
export const partner = {
  namespaced: true,
  state: {
    hasSpouse: false,
    hasContract: false,
    share: 0,
    hasPartner: false,
  },
  mutations: {
    setValue,
  },
  actions: {
    resetSpouse(context) {
      context.commit('setValue', { key: 'hasContract', value: false });
      context.commit('setValue', { key: 'share', value: 0 });
    },
  },
};
