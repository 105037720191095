export function setValue(state, settings) {
  const { key, value, submodule } = settings;

  if (submodule) {
    state[submodule][key] = value;
  } else {
    state[key] = value;
  }
}

export function initiateHierarchyLevel(state, settings) {
  const {
    level, index, belongsTo, submodule,
  } = settings;
  if (submodule) {
    state[submodule][level][index] = {
      alive: 0,
      dead: 0,
      belongsTo: belongsTo || 0,
    };
  } else {
    state[level][index] = {
      alive: 0,
      dead: 0,
      belongsTo: belongsTo || 0,
    };
  }
}

export function setHierarchyValue(state, settings) {
  const {
    level, index, key, value, submodule,
  } = settings;

  if (submodule) {
    state[submodule][level][index][key] = value;
  } else {
    state[level][index][key] = value;
  }
}

export function deleteHierarchyNode(state, settings) {
  const { level, index, submodule } = settings;

  if (submodule) {
    delete state[submodule][level][index];
  } else {
    delete state[level][index];
  }
}
