import getInitialState from '@/components/Alert/model';

// eslint-disable-next-line
export const alert = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    show(state) {
      state.isVisible = true;
    },
    hide(state) {
      state.isVisible = false;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setTimeoutID(state, ID) {
      state.timeoutID = ID;
    },
  },
  actions: {
    show({ commit, dispatch }, message) {
      commit('setMessage', message);
      commit('show');
      const ID = setTimeout(() => {
        dispatch('hide');
      }, 5000);
      commit('setTimeoutID', ID);
    },
    hide({ commit, state }) {
      if (state.timeoutID) {
        clearTimeout(state.timeoutID);
      }
      commit('setMessage', '');
      commit('hide');
    },
  },
};
