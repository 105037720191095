import getInitialState from '@/components/Modal/model';

// eslint-disable-next-line
export const modal = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    show(state) {
      state.isVisible = true;
    },
    hide(state) {
      state.isVisible = false;
    },
    setMessage(state, content) {
      state.hl = content.hl;
      state.message = content.message;
    },
  },
  actions: {
    show({ commit }, content) {
      commit('setMessage', content);
      commit('show');
    },
    hide({ commit }) {
      commit('setMessage', {
        hl: '',
        message: '',
      });
      commit('hide');
    },
  },
};
