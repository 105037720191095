<template>
  <div class="content">
    <div class="left">
      <!-- <h2 class="h3">{{content.intro_title}}</h2> -->
      <h2 class="h3">
        <span>{{content.intro_title}}</span>
      </h2>
      <p class="description">{{content.intro_copy}}</p>
      <checkbox
        class="description"
        :label="disclaimer"
        @update="toggleDisclaimer"
      />
      <unicef-button
        :label="content.intro_cta"
        :is-pink="true"
        :disabled="enabled === false"
        icon-width="18"
        icon-height="13"
        @press="$router.push({ path: '/spouse' })"
      >
        <arrow-right/>
      </unicef-button>
    </div>
    <img class="right img-right" :src="content.intro_image" alt="Startbild">
  </div>
</template>

<script>
import QuestionMixin from '@/mixins/QuestionMixin';
import Checkbox from '@/components/Checkbox.vue';
import UnicefButton from '@/components/UnicefButton.vue';
import ArrowRight from '@/assets/ArrowRight.vue';

export default {
  mixins: [QuestionMixin],
  data() {
    return {
      enabled: false,
    };
  },
  methods: {
    toggleDisclaimer(value) {
      this.enabled = value;
    },
  },
  components: {
    Checkbox,
    UnicefButton,
    ArrowRight,
  },
  computed: {
    disclaimer() {
      if (Object.keys(this.content).length > 0) {
        return this.content.disclaimer_application
          .replace('Datenschutzerklärung', `<a href="${this.content.terms_link || ''}" target="_blank">Datenschutzerklärung</a>`)
          .replace('Datenschutzhinweis', `<a href="${this.content.privacy_policy_url || ''}" target="_blank">Datenschutzhinweis</a>`);
      }
      return '';
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  overflow: hidden;
}

.h3 {
  margin-left: -120px;
  z-index: 1;
  position: relative;
}

.h3 span {
  display: inline;
  padding: 0.35em 0em;
  background-color: rgba(3,148,210,.9);
  box-shadow: 1em 0 0 rgba(3,148,210,.9), -1em 0 0 rgba(3,148,210,.9);
  box-decoration-break: clone;
  line-height: 1.9;
  color: white;
  hyphens: auto;
}

.left {
  width: 50%;
  padding: 40px 60px;
  box-sizing: border-box;
  position: relative;
}
.left:before{
  content: '';
  background-color: var(--blue-10);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 65vw;
  z-index: -1;
}
.img-right {
  position: fixed;
  top: var(--nav-height);
  left: 0;
  width: 50vw;
  height: 100vh;
  object-position: 36%;
  object-fit: cover;
}
/deep/ .checkbox {
  align-items: flex-start;
}
@media(max-width: 768px) {
  .h3 {
    margin-left: 20px;
  }
  .h3 span {
    box-shadow: 0.5em 0 0 rgba(3,148,210,.9), -0.5em 0 0 rgba(3,148,210,.9);
  }
  .content {
    margin: 0 -10px;
  }
  .left {
    padding: 20px;
    padding-top: 40px;
    width: 100%;
  }
  .left:before {
    background-color: transparent;
  }
  .right {
    display: none;
  }
}
@media(max-width: 767px) {
  .left {
    padding-top: 20px;
  }
  .h3 {
    margin-left: 0px;
  }
  .h3 span {
    background-color: transparent;
    box-shadow: none;
    color: var(--blue-25);
    line-height: 1.3;
  }
}
@media(min-width: 768px) {
  .content {
    margin: 0 -10px;
  }
}
</style>
