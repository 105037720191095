import {
  setValue, setHierarchyValue, initiateHierarchyLevel, deleteHierarchyNode,
} from '@/store/mutations';
import resetSettings, { handleFirstLevel, handleNextLevel } from '@/store/actions';
import getInitialHalfsiblingsFatherState from '@/parentel2/model-halfsiblings-father';
import getInitialHalfsiblingsMotherState from '@/parentel2/model-halfsiblings-mother';
import getInitialSiblingsState from '@/parentel2/model-siblings';

export default {
  namespaced: true,
  state: {
    hasFather: false,
    hasMother: false,
    ...getInitialHalfsiblingsFatherState(),
    ...getInitialHalfsiblingsMotherState(),
    ...getInitialSiblingsState(),
  },
  mutations: {
    setValue,
    setHierarchyValue,
    initiateHierarchyLevel,
    deleteHierarchyNode,
  },
  actions: {
    resetSettings,
    handleFirstLevel,
    handleNextLevel,
  },
};
