/* eslint-disable import/prefer-default-export */
export function trackEvent(eventAction, eventLabel) {
  if (
    !window.dataLayer
    || !window.dataLayer.push
    || typeof window.dataLayer.push !== 'function'
  ) {
    return;
  }

  /* eslint-disable */
  if (
    window.location.host.indexOf('localhost') > -1
    || window.location.host.indexOf('digitalists') > -1
  ) {
    console.info(`Pushing event: "gaEvent" with eventCategory: "Erbrechner", eventAction: "${eventAction}", eventLabel; "${eventLabel}"`);
  }
  /* eslint-enable */

  window.dataLayer.push({
    event: 'gaEvent',
    eventCategory: 'Erbrechner',
    eventAction,
    eventLabel,
  });
}
