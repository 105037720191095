<template>
  <div
    :class="{
      'alert': true,
      'is-visible': isVisible,
    }"
  >
    <div class="alert-message">
      <span>{{message}}</span>
      <unicef-button
        :label="ctaLabel"
        @press="dismiss"
        iconWidth="0"
        iconHeight="0"
        :isWhite="true"
      />
    </div>
  </div>
</template>

<script>
import UnicefButton from '@/components/UnicefButton.vue';

import { trackEvent } from '@/utils/tracking';

export default {
  props: {
    ctaLabel: {
      type: String,
      required: true,
    },
  },
  components: {
    UnicefButton,
  },
  methods: {
    dismiss() {
      this.$store.dispatch('alert/hide');
    },
  },
  computed: {
    message() {
      return this.$store.state.alert.message;
    },
    isVisible() {
      return this.$store.state.alert.isVisible;
    },
  },
  watch: {
    isVisible(current) {
      if (!current) {
        return;
      }

      trackEvent('error', this.message);
    },
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  0% {
    transform: translate3d(0,60px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: var(--footer-height);
  transform: translate3d(0,60px,0);
  opacity: 0;
  background-color: var(--pink-20);
  color: var(--white);
  padding: 20px 20px 20px 20px;
  right: 0;
}
.alert-message {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.is-visible {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both
}
@media(max-width: 768px) {
  .alert {
    padding: 10px;
  }
}
</style>
