<template>
  <div
    :class="{
      'modal': true,
      'is-visible': isVisible,
    }"
  >
    <div class="modal-message">
      <h2>{{hl}}</h2>
      <span>{{message}}</span>
      <div class="buttons">
        <unicef-button
          :label="ctaYes"
          iconWidth="0"
          iconHeight="0"
          :isPink="true"
          :removeIcon="true"
          @press="$emit('pressYes')"
        />
        <unicef-button
          :label="ctaNo"
          iconWidth="0"
          iconHeight="0"
          :isPink="true"
          :removeIcon="true"
          @press="$emit('pressNo')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UnicefButton from '@/components/UnicefButton.vue';

export default {
  props: {
    ctaYes: {
      type: String,
      required: true,
    },
    ctaNo: {
      type: String,
      required: true,
    },
  },
  components: {
    UnicefButton,
  },
  methods: {
    dismiss() {
      this.$store.dispatch('modal/hide');
    },
  },
  computed: {
    hl() {
      return this.$store.state.modal.hl;
    },
    message() {
      return this.$store.state.modal.message;
    },
    isVisible() {
      return this.$store.state.modal.isVisible;
    },
  },
};
</script>

<style scoped>
.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.modal:before {
  content: '';
  background-color: black;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.modal-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  background-color: var(--white);
  box-shadow: 0px 14px 30px 0px rgba(0,0,0,0.5);
  border-radius: 10px;
  padding: 20px;
  z-index: 2;
  text-align: center;
  margin: 10px;
}
.is-visible {
  display: flex;
  z-index: 100;
}
h2,
.buttons {
  margin-top: 50px;
}
.buttons > .btn:first-child {
  margin-right: 20px;
}
</style>
