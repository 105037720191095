export function countNodesInLevel(level, belongsTo) {
  let count = 0;
  Object.values(level).forEach((node) => {
    if (node.belongsTo === belongsTo) {
      count += 1;
    }
  });
  return count;
}

export function getNewNodeIndex() {
  return `${Date.now()}.${Math.random().toString(16).slice(2)}`;
}

export function getNodeIndexesToDelete(level, belongsTo, count) {
  const levelArrayIndexes = Object.keys(level).reverse();
  const indexes = [];

  for (let i = 0; i < levelArrayIndexes.length; i += 1) {
    const index = levelArrayIndexes[i];
    if (level[index].belongsTo === belongsTo) {
      indexes.push(index);

      if (indexes.length === count) {
        break;
      }
    }
  }

  return indexes;
}
