import getInitialChildrenState from '@/parentel1/model';
import getInitialHalfsiblingsFatherState from '@/parentel2/model-halfsiblings-father';
import getInitialHalfsiblingsMotherState from '@/parentel2/model-halfsiblings-mother';
import getInitialSiblingsState from '@/parentel2/model-siblings';
import getInitialFatherFatherHalfsiblingsState from '@/parentel3/model-father-father-halfsiblings';
import getInitialFatherMotherHalfsiblingsState from '@/parentel3/model-father-mother-halfsiblings';
import getInitialFatherAuntsState from '@/parentel3/model-father-aunts';
import getInitialMotherFatherHalfsiblingsState from '@/parentel3/model-mother-father-halfsiblings';
import getInitialMotherMotherHalfsiblingsState from '@/parentel3/model-mother-mother-halfsiblings';
import getInitialMotherAuntsState from '@/parentel3/model-mother-aunts';
import getInitialGreatGrandmotherState from '@/parentel4/model-greatgrandmother';
import initialGreatGrandfatherState from '@/parentel4/model-greatgrandfather';

import { countNodesInLevel, getNewNodeIndex, getNodeIndexesToDelete } from '@/utils/hierarchy';

const initialSettings = {
  children: getInitialChildrenState(),
  halfsiblingsFather: getInitialHalfsiblingsFatherState(),
  halfsiblingsMother: getInitialHalfsiblingsMotherState(),
  siblings: getInitialSiblingsState(),
  fatherFatherHalfsiblings: getInitialFatherFatherHalfsiblingsState(),
  fatherMotherHalfsiblings: getInitialFatherMotherHalfsiblingsState(),
  fatherAunts: getInitialFatherAuntsState(),
  motherFatherHalfsiblings: getInitialMotherFatherHalfsiblingsState(),
  motherMotherHalfsiblings: getInitialMotherMotherHalfsiblingsState(),
  motherAunts: getInitialMotherAuntsState(),
  greatGrandmother: getInitialGreatGrandmotherState(),
  greatGrandfather: initialGreatGrandfatherState(),
};

export default function (context, settingsKey) {
  const settings = initialSettings[settingsKey];
  // JSON.parse and stringify is used to deep copy initial nested object settings
  Object.keys(settings).forEach((key) => {
    context.commit('setValue', {
      key,
      value: typeof (settings[key]) === 'object' ? JSON.parse(JSON.stringify(settings[key])) : settings[key],
    });
  });
}

export function handleFirstLevel({ commit, state, dispatch }, settings) {
  const { value, submodule } = settings;
  let values = [];

  if (submodule) {
    values = Object.keys(state[submodule].firstDead);
  } else {
    values = Object.keys(state.firstDead);
  }

  const existingValues = values.length;

  if (existingValues < value) {
    for (let i = existingValues; i < value; i += 1) {
      commit('initiateHierarchyLevel', {
        level: 'firstDead',
        index: getNewNodeIndex(),
        submodule,
      });
    }
  } else {
    for (let i = existingValues; i > value; i -= 1) {
      const index = values[i - 1];
      commit('deleteHierarchyNode', {
        level: 'firstDead',
        index,
        submodule,
      });

      /*
       * Delete corresponding and depending children to avoid having unused references
       */
      dispatch('handleNextLevel', {
        level: 'secondDead',
        value: 0,
        belongsTo: index,
      });
    }
  }
}

/*
 * Generic handling for all levels in hierarchy except of first level
 * In case of extending to support more than secondDead level just pass level
 * as Parameter
 */
export function handleNextLevel({ commit, state }, settings) {
  const { value, belongsTo, submodule } = settings;
  let existingValues = 0;

  if (submodule) {
    existingValues = countNodesInLevel(state[submodule].secondDead, belongsTo);
  } else {
    existingValues = countNodesInLevel(state.secondDead, belongsTo);
  }

  if (existingValues < value) {
    for (let i = existingValues; i < value; i += 1) {
      commit('initiateHierarchyLevel', {
        level: 'secondDead',
        index: getNewNodeIndex(),
        belongsTo,
        submodule,
      });
    }
  } else {
    const nodesCountToDelete = existingValues - value;
    let module = state.secondDead;

    if (submodule) {
      module = state[submodule].secondDead;
    }

    const nodesToDelete = getNodeIndexesToDelete(module, belongsTo, nodesCountToDelete);
    nodesToDelete.forEach((nodeIndex) => {
      commit('deleteHierarchyNode', {
        level: 'secondDead',
        index: nodeIndex,
        submodule,
      });
    });
  }
}
