import {
  setValue, setHierarchyValue, initiateHierarchyLevel, deleteHierarchyNode,
} from '@/store/mutations';
import resetSettings, { handleFirstLevel, handleNextLevel } from '@/store/actions';
import getInitialFatherFatherHalfsiblingsState from '@/parentel3/model-father-father-halfsiblings';
import getInitialFatherMotherHalfsiblingsState from '@/parentel3/model-father-mother-halfsiblings';
import getInitialFatherAuntsState from '@/parentel3/model-father-aunts';
import getInitialMotherFatherHalfsiblingsState from '@/parentel3/model-mother-father-halfsiblings';
import getInitialMotherMotherHalfsiblingsState from '@/parentel3/model-mother-mother-halfsiblings';
import getInitialMotherAuntsState from '@/parentel3/model-mother-aunts';

export default {
  namespaced: true,
  state: {
    hasGrandparents: false,
    hasFatherFather: false,
    hasFatherMother: false,
    hasMotherFather: false,
    hasMotherMother: false,
    ...getInitialFatherFatherHalfsiblingsState(),
    ...getInitialFatherMotherHalfsiblingsState(),
    ...getInitialFatherAuntsState(),
    ...getInitialMotherFatherHalfsiblingsState(),
    ...getInitialMotherMotherHalfsiblingsState(),
    ...getInitialMotherAuntsState(),
  },
  mutations: {
    setValue,
    setHierarchyValue,
    initiateHierarchyLevel,
    deleteHierarchyNode,
  },
  actions: {
    resetSettings,
    handleFirstLevel,
    handleNextLevel,
  },
};
