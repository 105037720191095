export default function () {
  return {
    hasFatherMotherHalfsiblings: false,
    halfsiblingsFatherMother: {
      rootAlive: 0,
      rootDead: 0,
      firstDead: {}, // contains detailed information to children/grandchildren
      secondDead: {}, // contains detailed information to grandchildren/great grandchildren
    },
  };
}
