export default function () {
  return {
    hasSiblings: false,
    siblings: {
      rootAlive: 0,
      rootDead: 0,
      firstDead: {}, // contains detailed information to children/grandchildren
      secondDead: {}, // contains detailed information to grandchildren/great grandchildren
    },
  };
}
