<template>
  <button
    :class="{
      'btn': true,
      'btn--reverse': iconLeft,
      'btn--pink': isPink,
      'btn--white': isWhite,
      'btn--no-icon': removeIcon,
    }"
    :type="type"
    name="button"
    @click="$emit('press')"
    :disabled="disabled"
    >
      <span class="label">{{label}}</span>
      <span class="circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
          :style="{
            width: `${iconWidth}px`,
            height: `${iconHeight}px`,
          }"
        >
          <slot/>
        </svg>
      </span>
    </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    iconLeft: {
      type: Boolean,
    },
    iconWidth: {
      type: String,
    },
    iconHeight: {
      type: String,
    },
    isPink: {
      type: Boolean,
    },
    isWhite: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style scoped>
/* reset button style and style new buttons */
button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.btn {
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 4px;
  border: 2px solid var(--blue-25);
  color: var(--blue-25);
  font-family: 'Univers LT W01 55 Roman';
}

.btn--reverse {
  flex-direction: row-reverse;
}

.btn:hover {
  cursor: pointer;
}

.btn .label {
  padding: 0 20px;
}

.circle {
  display: block;
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-10);
}

.btn svg {
  fill: var(--blue-25);
}

.btn:hover .circle {
  background-color: var(--blue-25);
}

.btn:hover svg {
  fill: var(--white);
}

.btn:disabled.btn--pink {
  opacity: 0.4;
}

.btn--pink {
  background-image: linear-gradient(0deg, #C70071 0%, #FC0E94 100%);
  box-shadow: inset 0 1px 2px 0 #EE80BE, inset 0 -1px 3px 0 #A71A6A;
  color: var(--white);
  border: none;
}

.btn--pink .circle {
  background-color: var(--pink-25);
}

.btn--pink svg {
  fill: var(--white);
}

.btn--pink:hover {
  background: #C80B77;
  box-shadow: inset 0 -2px 4px 0 #B2176F, inset 0 2px 4px 0 #B70067;
}

.btn--pink:hover .circle {
  background-color: var(--pink-30);
}

.btn--white {
  background-color: var(--white);
  color: var(--pink-25);
  border: none;
  min-height: 45px;
}

.btn--white:hover {
  color: var(--pink-30);
}

.btn--white .circle {
  display: none;
}

.btn--no-icon {
  min-height: 45px;
}

.btn--no-icon .circle {
  display: none;
}
</style>
