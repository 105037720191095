import { setValue } from '@/store/mutations';
import resetSettings from '@/store/actions';
import getInitialGreatGrandmotherState from '@/parentel4/model-greatgrandmother';
import getInitialGreatGrandfatherState from '@/parentel4/model-greatgrandfather';

export default {
  namespaced: true,
  state: {
    ...getInitialGreatGrandmotherState(),
    ...getInitialGreatGrandfatherState(),
  },
  mutations: {
    setValue,
  },
  actions: {
    resetSettings,
  },
};
