<template>
  <div
    id="app"
    ref="app"
    :data-url="content.project_url"
    :class="{
      'fullbleed': this.$route && this.$route.name && this.$route.name.indexOf('result-') > -1,
    }"
  >
    <div v-if="loaded === false" class="loading">
      <img
        class="loading-img"
        src="./assets/images/Loading.gif"
        alt="Laden"
      >
    </div>
    <div
      :class="{
        'nav-wrapper': true,
        'nav-wrapper--transparent': $route.name === 'start',
      }"
      ref="navWrapper"
    >
      <nav class="main-nav">
        <h1>
          <logo class="logo"/>
          <span>{{content.page_title}}</span>
        </h1>
        <unicef-button
          :label="content.ctaQuit"
          icon-width="20"
          icon-height="19"
          v-show="$route.name !== 'start'"
          @press="$store.dispatch('modal/show', {
            hl: content.cancel_hl,
            message: content.cancel_desc,
          })"
        >
          <Home/>
        </unicef-button>
      </nav>
    </div>
    <div class="main-area">
      <router-view/>
    </div>
    <alert
      :ctaLabel="content.alert_cta || ''"
    />
    <modal
      :ctaYes="content.cancel_cta_yes || ''"
      :ctaNo="content.cancel_cta_no || ''"
      @pressNo="$store.dispatch('modal/hide')"
      @pressYes="goToWebsite"
    />
    <footer>
      <nav class="footer-nav">
        <ul>
          <li><a href="https://www.unicef.de/informieren/ueber-uns/impressum" target="_blank" rel="noopener">Impressum</a></li>
          <li><a :href="content.terms_link">Datenschutzerklärung</a></li>
          <li><a :href="content.privacy_policy_url">Datenschutzhinweis</a></li>
        </ul>
      </nav>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from '@/components/Alert/Alert.vue';
import Modal from '@/components/Modal/Modal.vue';
import UnicefButton from '@/components/UnicefButton.vue';
import Logo from '@/assets/Logo.vue';
import Home from '@/assets/Home.vue';

export default {
  name: 'app',
  components: {
    Alert,
    Modal,
    UnicefButton,
    Logo,
    Home,
  },
  data() {
    return {
      trackOnlyRouteChange: false,
    };
  },
  updated() {
    if (this.trackOnlyRouteChange) {
      return;
    }

    this.trackPageView();
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (!this || !this.$refs || !this.$refs.navWrapper || !this.$refs.navWrapper.classList) {
        return;
      }

      if (window.scrollY > 0) {
        this.$refs.navWrapper.classList.add('nav-wrapper--scrolled');
      } else {
        this.$refs.navWrapper.classList.remove('nav-wrapper--scrolled');
      }
    });
  },
  computed: {
    ...mapGetters([
      'store',
      'content',
    ]),
    loaded() {
      return Object.keys(this.store.content).length > 0;
    },
  },
  methods: {
    goToWebsite() {
      window.location.href = this.$refs.app.dataset.url;
    },
    trackPageView() {
      let routePath = this.$route.fullPath;

      if (routePath && routePath.indexOf('/') === 0) {
        routePath = routePath.substr(1);
      }

      if (routePath === '') {
        routePath = this.$route.name;
      }

      if (
        !window.dataLayer
        || !window.dataLayer.push
        || typeof window.dataLayer.push !== 'function'
      ) {
        return;
      }

      /* eslint-disable */
      if (
        window.location.host.indexOf('localhost') > -1
        || window.location.host.indexOf('digitalists') > -1
      ) {
        console.info(`Pushing event: "Page Mega" with pageCategory: "Erbrechner", pageSubcategory: ${routePath}, pageType; "Applikation"`);
      }
      /* eslint-enable */

      window.dataLayer.push({
        event: 'Page Meta', // please do not change
        pageCategory: 'Erbrechner',
        pageSubcategory: routePath || undefined,
        pageType: 'Applikation' || undefined,
        language: 'de',
        userAgent: navigator.userAgent,
        originalPageUrl: window.location.pathname + window.location.search,
      });
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        this.trackOnlyRouteChange = true;
        this.trackPageView();
      },
    },
  },
};
</script>

<style>
@import '../public/css/fonts.css';

:root {
  --app-max-width: 1280px;
  --question-max-width: 740px;
  --result-max-width: 920px;
  --nav-height: 100px;
  --footer-height: 50px;
  --blue-05: #EDF8FC;
  --blue-10: #D9F4FF;
  --blue-15: #6ECCF4;
  --blue-20: #7ED2F5;
  --blue-25: #007db3;
  --blue-30: #076993;
  --pink-20: #C70071;
  --pink-25: #C80B77;
  --pink-30: #AE0C69;
  --gray-10: #E1E1E1;
  --gray-20: #9E9E9E;
  --gray-30: #323232;
  --white: #FFF;
  --input-fontsize: 20px;
}

/*
 use following media query dimensions
 desktop: 1024;
 tablet: 768px;
 mobile: 374px;
*/

body {
  padding: 0 60px;
  padding-top: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  font-family: 'Univers LT W01 45 Light';
  overflow-x: hidden;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
}

#app {
  width: 100%;
  max-width: var(--app-max-width);
  height: 100%;
  display: flex;
  flex-direction: column;
}

#app.fullbleed {
  max-width: 100%;
}

.main-area {
  padding-top: var(--nav-height);
  padding-bottom: var(--footer-height);
  min-height: calc(100vh - var(--nav-height) - var(--footer-height) - 20px);
  display: flex;
}

.nav-wrapper {
  position: fixed;
  background-color: var(--white);
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
  padding: 0 60px;
  z-index: 2;
}

.nav-wrapper--scrolled {
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.25);
}

.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  position: sticky;
  top: 0;
  background: var(--white);
  max-width: var(--app-max-width);
  margin-left: auto;
  margin-right: auto;
}

.loading {
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.loading-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 150px;
  height: 54px;
}

h1 {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 60px;
  display: flex;
  align-items: center;
}

h1 > span {
  position: absolute;
  top: -99999em;
}

h2 {
  color: var(--blue-25);
  font-size: 35px;
  margin-top: 5px;
  margin-bottom: 25px;
  font-family: 'Univers LT W01 45 Light';
}

h3 {
  color: var(--gray-20);
  font-weight: 300;
  margin: 0;
  font-family: 'Univers LT W01 55 Roman';
}

h4 {
  color: var(--blue-25);
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Univers LT W01 45 Light';
}

.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--question-max-width);
  padding-top: 50px;
  width: 100%;
}

.question h2,
.question h3,
.question p {
  text-align: center;
}

.description {
  margin-bottom: 30px;
  margin-top: 0;
  line-height: 1.5;
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 50px;
}

.buttons > .button:first-child {
  margin-right: 40px;
}

.radios {
  display: flex;
  align-items: center;
}

.radios > .radio:first-child {
  margin-right: 30px;
}

.result {
  width: 100%;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 1px solid var(--blue-10);
  padding: 0;
  margin-right: 30px;
}

ul > li {
  line-height: 2;
  padding: 0 10px;
}

ul > li:nth-child(even) {
  background-color: var(--blue-10);
}

@media(max-width: 1024px) {
  body {
    padding: 0 10px;
  }

  .nav-wrapper {
    padding: 0 20px;
  }
}

@media(max-width: 374px) {
  .nav-wrapper {
    padding: 0 5px;
  }

  .buttons > .button:first-child {
    margin-right: 5px;
  }
}

/*
 * IE 11 specific
 */
h2,
p {
  width: 100%;
  max-width: var(--question-max-width);
}

.ie11-center {
  margin: 0 auto;
}

form input,
form select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
}
form input,
form .input,
form select {
  border: 1px solid var(--blue-20);
  border-radius: 4px;
  padding: 0 10px;
  height: 45px;
  font-size: 18px;
  color: var(--gray-30);
}
form .radio {
  display: flex;
  align-items: center;
}
form .radio input {
  display: none;
}
form .radio input:checked + .fake-radio::after,
form input[type=checkbox]:checked + .fake-checkbox::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background-color: var(--blue-25);
  border-radius: 50%;
}
form input[type=checkbox]:checked + .fake-checkbox::after {
  border-radius: 2px;
}
form .radio input:checked ~ .radio-label {
  font-weight: bold;
}
form .radio .fake-radio,
form input[type=checkbox] + .fake-checkbox {
  display: block;
  border: 1px solid var(--blue-15);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  background-color: white;
}
form input[type=checkbox] + .fake-checkbox {
  border-radius: 4px;
  min-width: 28px;
  min-height: 28px;
}
form .radio .radio-label {
  color: var(--blue-25);
  font-family: 'Univers LT W01 55 Roman';
}
form input[type=checkbox] {
  display:  none;
}
form .row--terms label {
  display: flex;
}
form .row--terms label span:first-of-type {
  display: block;
  font-family: 'Univers LT W01 55 Roman';
  color: var(--blue-25);
  font-size: 18px;
  line-height: 28px;
}
form .row--terms label span:last-of-type {
  font-size: 14px;
}
.nav-wrapper--transparent,
.nav-wrapper--transparent .main-nav {
  background-color: transparent;
}

.nav-wrapper--transparent {
  z-index: 9;
}

.nav-wrapper--transparent:before  {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 65vw;
  height: 100%;
  background-color: var(--blue-10);
}

@media(max-width: 768px) {
  .nav-wrapper--transparent:before  {
    width: 100%;
    background-color: white;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--footer-height);
  display: flex;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  padding: 0 60px;
  box-sizing: border-box;
  font-size: 15px;
}

@media(max-width: 1024px) {
  footer {
    padding: 0 20px;
  }
}

@media(max-width: 500px) {
  footer {
    position: relative;
    bottom: auto;
    left: auto;
    height: auto;
    margin-left: -10px;
    width: calc(100% + 20px);
    background: var(--blue-05);
    padding: 10px 0;
  }
}

footer .footer-nav {
  width: 100%;
  max-width: var(--app-max-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
}

footer ul {
  margin: 0 0 0 auto;
  display: flex;
  column-gap: 30px;
  flex-direction: row;
  border: none;
}

@media(max-width: 768px) {
  footer ul {
    margin: 0 auto;
  }
}

@media(max-width: 500px) {
  footer ul {
    flex-direction: column;
    column-gap: 0;
    align-items: center;
  }
}

footer ul li {
  background-color: transparent !important;
  padding: 0;
}

footer ul a {
  color: #044c6a;
}
</style>
