<template>
  <label class="checkbox">
    <input
      ref="checkbox"
      type="checkbox"
      :name="name"
      :checked="checked === true"
      @change="change"
    >
    <span class="fake-checkbox"></span>
    <span class="checkbox-label" v-html="label"></span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
  },
  methods: {
    change() {
      this.$emit('update', this.$refs.checkbox.checked === true);
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}

input:checked + .fake-checkbox::after {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: var(--blue-25);
}

.checkbox {
  display: flex;
  align-items: center;
}

.fake-checkbox {
  display: block;
  background-color: var(--blue-05);
  border: 1px solid var(--blue-15);
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  margin-right: 20px;
  position: relative;
}

.checkbox-label {
  color: var(--blue-25);
  font-size: 14px;
  margin-top: 2px;
}

.checkbox-label >>> a {
  color: var(--blue-30);
}
</style>
