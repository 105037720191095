import { mapGetters } from 'vuex';
import cssVars from 'css-vars-ponyfill';

export default {
  created() {
    // Add support for css variables to IE11
    cssVars();
    // content already loaded
    if (Object.keys(this.content).length > 0) {
      return;
    }
    this.$store.dispatch('fetchContent');
  },
  computed: {
    ...mapGetters([
      'content',
    ]),
  },
};
