import {
  setValue, setHierarchyValue, initiateHierarchyLevel, deleteHierarchyNode,
} from '@/store/mutations';
import resetSettings, { handleFirstLevel, handleNextLevel } from '@/store/actions';
import getInitialChildrenState from '@/parentel1/model';

export default {
  namespaced: true,
  state: getInitialChildrenState(),
  mutations: {
    setValue,
    setHierarchyValue,
    initiateHierarchyLevel,
    deleteHierarchyNode,
  },
  actions: {
    resetSettings,
    handleFirstLevel,
    handleNextLevel,
  },
};
