import 'es6-promise/auto';
import 'classlist-polyfill';
import { createStore } from 'vuex';
import axios from 'axios';
import { setValue } from '@/store/mutations';
import { alert } from '@/components/Alert/store';
import { modal } from '@/components/Modal/store';
import { partner } from '@/partner/store';
import parentel1 from '@/parentel1/store';
import parentel2 from '@/parentel2/store';
import parentel3 from '@/parentel3/store';
import parentel4 from '@/parentel4/store';

export default createStore({
  state: {
    savings: 0,
    content: {},
  },
  modules: {
    alert,
    modal,
    partner,
    parentel1,
    parentel2,
    parentel3,
    parentel4,
  },
  mutations: {
    setValue,
    setContent(state, content) {
      state.content = content;
      document.title = content.page_title || '';
    },
  },
  actions: {
    async fetchContent(context) {
      let content = {};

      if (
        window.location.host.indexOf('localhost:8080') > -1
        || window.location.host.indexOf('192.168.0.26') > -1
        || window.location.host.indexOf('192.168.0.27') > -1
      ) {
        content = await axios.get('./contentMock.json');
      } else {
        content = await axios.get('/wp-json/calculator/v1/fields');
      }

      context.commit('setContent', content.data);
    },
  },
  getters: {
    store: (state) => state,
    content: (state) => state.content,
  },
});
